.video-background {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 20%;
  /* height: 20px; */
}


.home-welcome-video {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
  color: #fff;
  width: 80%; 
  max-width: 600px; 
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  height: 100% !important;
}

@media (max-width: 1024px) {
  .content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    color: #fff;
    width: 80%; 
    max-width: 600px; 
  }
  
.home-welcome-video {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
}

@media (max-width: 1024px) {
  .content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    color: #fff;
    width: 80%; 
    max-width: 600px; 
  }
  
.home-welcome-video {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
}


@media (max-width: 1100px) {
  .content {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    color: #fff;
    width: 80%; 
    max-width: 600px; 
  }
  
.home-welcome-video {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
}
@media (max-width: 765px) {
  .content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    color: #fff;
    width: 80%; 
    max-width: 600px; 
  }
  .home-welcome-video {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}


@media (max-width: 600px) {
  .content {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    color: #fff;
    width: 80%; 
    max-width: 600px; 
  }
  .content h1 {
   margin:0px;
   font: 25px;
  }
  
.home-welcome-video {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
}
.ant-carousel {
  width: 50%;
  margin: 0 auto;
}

.ant-carousel .slick-slide {
  text-align: center;
  padding: 200px;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.ant-carousel img {
  max-width: 100%;
  border-radius: 5px;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.home-title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-us-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-us-card {
  border-radius: 15px;
  /* height: 410px; */
  background-size: cover;
}

.about-us-card img {
  object-fit: cover;
  height: 700px;
}

.about-us-slider {
  width: 600px;
  /* border: 1px solid green; */
}


/* .about-us {
  margin-right: 20px;
}

@media (max-width: 1000px) {
 
.about-us {
  margin-right: 0px !important;
}

 
} */



.about-us h1 {
  color: #2b2676;
  font-weight: bold;
  padding-top: 30px;

}
.about-us h3 {
  /* padding-top: 30px; */
}

.about-us p {
  padding-top: 30px;
  line-height: 2;
}

.home-title h3 {
  text-align: center;
  font-weight: bold;
  color: #2b2676;
}
.home-title hr {
  margin-left: auto;
  margin-right: auto;
  border-bottom: 2px solid #2b2676;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  /* border: 2px; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);  */
}

/* .swiper-slide-active img {
  transform: scale(1.1); 
  transition: transform 0.5s ease;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  margin: 20px auto;
}

.append-buttons {
  text-align: center;
  margin-top: 20px;
}

.append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

.padding-for-sections {
  padding-top: 130px;
}

.responsive-img {
  width: 77%;
  /* width: 100%; */
  height: auto;
  object-fit: cover; /* Adjust the object-fit property based on your design needs */
  display: flex !important;
  align-items: center !important;
  
}


.css-dev-only-do-not-override-2q8sxy .ant-col-xl-4 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 16.666666666666664%;
  /* max-width: 16.666666666666664%; */
  /* display: flex; */
  align-items: center;
}

.swiper-pagination-fraction{
  display: none;
}
.ant-list-items{
  max-height: 200px !important;
}

.ant-spin-container{
  
  z-index: 10000;
}

.swiper-slide{
  transition: all 0.5s;
}
.swiper-slide:hover{
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* transition: all 0.5s; */

}
.my-swiper.swiper-slide-active{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

}

.welcome-carousel img {
  filter: brightness(50%);
}

@media (max-width: 1140px) {
  .about-us-card img {
    object-fit: cover;
    height: 400px;
  }
}

@media (max-width: 530px) {
  .about-us-card img {
    object-fit: cover;
    height: 300px;
  }
}


@media (min-width: 530px) {
  .about-us-card img {
    object-fit: cover;
    height: 300px;
  }
}


@media screen and (min-width: 1501px) {
  .container-for-all {
    width: 100%; /* Allow container to take full width */
    max-width: 1900px; /* Set a maximum width of 1500px */
    margin-left: auto; /* Push container to the center by adjusting left margin */
    margin-right: auto; /* Push container to the center by adjusting right margin */
  }
  
    .about-us-card img {
      object-fit: cover;
      height: 166px;
    }
  
  

  .content {
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    color: #fff;
    width: 80%; 
    max-width: 600px; 
  }
  .content h1 {
   margin:0px;
   font: 25px;
  }
  .home-welcome-video {
    width: 100%;
    height: 900px;
    object-fit: cover;
}

  /* Add any additional styles for other elements beyond 1500px */
}

