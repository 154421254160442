.tooltip-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s;
    background-color: rgb(255, 255, 255);
    padding: 11px 18px;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid rgb(211, 211, 211);
  }
  
  .text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .svgIcon {
    width: 16px;
    height: auto;
  }
  .tooltip {
    position: absolute;
    top: 0;
    left: 0%;
    transform: translateX(-50%);
    padding: 0.3em 0.6em;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    background: linear-gradient(
        109.6deg,
        rgb(103, 103, 182) 11.2%,
        rgb(178, 174, 209) 100.2%
      );
    border-radius: 12px;
    color: rgb(34, 34, 34);
    width: 200px !important;
  }
  .tooltip1 {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-50%);
    padding: 0.3em 0.6em;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    background: linear-gradient(
        109.6deg,
        rgb(103, 103, 182) 11.2%,
        rgb(178, 174, 209) 100.2%
      );
    border-radius: 12px;
    color: rgb(34, 34, 34);
    width: 200px;
  }
  
  .tooltip::before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    bottom: -0.2em;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background-color: rgb(255, 255, 255);
  }
  
  .tooltip1::before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    bottom: -0.2em;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background-color: rgb(255, 255, 255);
  }

  .tooltip-container:hover .tooltip1 {
    top: -130%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    padding: 11px 24px;
    color:white;

  }
  
  .tooltip-container:hover .tooltip {
    top: -130%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    padding: 11px 24px;
    color:white;


  }
  .sentence-title-div{
    padding:22px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  }
@media (max-width: 765px) {

  .sentence-title-name{
    font-size: 18px !important;
 
  }
  .sentence-title-div{
    padding:20px;
  }
}