/* Error Page */
.error-page {
  margin-bottom: 10%;
}
.error-page .clip .shadow {
  height: 180px; /*Contrall*/
}
.error-page .clip:nth-of-type(2) .shadow {
  width: 130px; /*Contrall play with javascript*/
}
.error-page .clip:nth-of-type(1) .shadow,
.error-page .clip:nth-of-type(3) .shadow {
  width: 250px; /*Contrall*/
}
.error-page .digit {
  width: 150px; /*Contrall*/
  height: 150px; /*Contrall*/
  line-height: 150px; /*Contrall*/
  font-size: 120px;
  font-weight: bold;
}
.error-page h2   /*Contrall*/ {
  font-size: 32px;
}
.error-page .msg /*Contrall*/ {
  top: -190px;
  left: 30%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 32px;
}
.error-page span.triangle    /*Contrall*/ {
  top: 70%;
  right: 0%;
  border-left: 20px solid #535353;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.error-page .container-error-404 {
  margin-top: 10%;

  position: relative;
  height: 250px;
  padding-top: 40px;
}
.error-page .container-error-404 .clip {
  display: inline-block;
  transform: skew(-45deg);
}
.error-page .clip .shadow {
  overflow: hidden;
}
.error-page .clip:nth-of-type(2) .shadow {
  overflow: hidden;
  position: relative;
  box-shadow: inset 20px 0px 20px -15px rgba(150, 150, 150, 0.8),
    20px 0px 20px -15px rgba(150, 150, 150, 0.8);
}

.error-page .clip:nth-of-type(3) .shadow:after,
.error-page .clip:nth-of-type(1) .shadow:after {
  content: "";
  position: absolute;
  right: -8px;
  bottom: 0px;
  z-index: 9999;
  height: 100%;
  width: 10px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(173, 173, 173, 0.8),
    transparent
  );
  border-radius: 50%;
}
.error-page .clip:nth-of-type(3) .shadow:after {
  left: -8px;
}
.error-page .digit {
  position: relative;
  top: 8%;
  color: white;
  background: #2b2676;
  border-radius: 50%;
  display: inline-block;
  transform: skew(45deg);
}
.error-page .clip:nth-of-type(2) .digit {
  left: -10%;
}
.error-page .clip:nth-of-type(1) .digit {
  right: -20%;
}
.error-page .clip:nth-of-type(3) .digit {
  left: -20%;
}
.error-page h2 {
  color: #a2a2a2;
  font-weight: bold;
  padding-bottom: 20px;
}
.error-page .msg {
  position: relative;
  z-index: 9999;
  display: block;
  background: #535353;
  color: #a2a2a2;
  border-radius: 50%;
  font-style: italic;
}
.error-page .triangle {
  position: absolute;
  z-index: 999;
  transform: rotate(45deg);
  content: "";
  width: 0;
  height: 0;
}

/* Error Page */

@media (max-width: 800px) {
  .error-page .clip:nth-of-type(1) .shadow,
  .error-page .clip:nth-of-type(3) .shadow {
    width: 150px; /*Contrall*/
  }

  .error-page .clip:nth-of-type(1) .shadow,
  .error-page .clip:nth-of-type(3) .shadow {
    width: 100px; /*Contrall*/
  }
  /* Error Page */
  .error-page .clip .shadow {
    height: 100px; /*Contrall*/
  }
  .error-page .clip:nth-of-type(2) .shadow {
    width: 80px; /*Contrall play with javascript*/
  }
  .error-page .clip:nth-of-type(1) .shadow,
  .error .clip:nth-of-type(3) .shadow {
    width: 100px; /*Contrall*/
  }
  .error-page .digit {
    width: 80px; /*Contrall*/
    height: 80px; /*Contrall*/
    line-height: 80px; /*Contrall*/
    font-size: 52px;
  }
  .error-page h2   /*Contrall*/ {
    font-size: 20px;
  }
  .error-page .msg /*Contrall*/ {
    top: -110px;
    left: 15%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .error-page span.triangle    /*Contrall*/ {
    top: 70%;
    right: -3%;
    border-left: 10px solid #535353;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  .error-page .container-error-404 {
    height: 150px;
  }
}

@media (max-width: 750px) {
  .error-page .clip:nth-of-type(1) .shadow,
  .error-page .clip:nth-of-type(3) .shadow {
    width: 100px; /*Contrall*/
  }
  /* Error Page */
  .error-page .clip .shadow {
    height: 100px; /*Contrall*/
  }
  .error-page .clip:nth-of-type(2) .shadow {
    width: 80px; /*Contrall play with javascript*/
  }
  .error-page .clip:nth-of-type(1) .shadow,
  .error .clip:nth-of-type(3) .shadow {
    width: 100px; /*Contrall*/
  }
  .error-page .digit {
    width: 80px; /*Contrall*/
    height: 80px; /*Contrall*/
    line-height: 80px; /*Contrall*/
    font-size: 52px;
  }
  .error-page h2   /*Contrall*/ {
    font-size: 24px;
  }
  .error-page .msg /*Contrall*/ {
    top: -110px;
    left: 15%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .error-page span.triangle    /*Contrall*/ {
    top: 70%;
    right: -3%;
    border-left: 10px solid #535353;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  .error-page .container-error-404 {
    height: 150px;
  }
  /* Error Page */
}

/*--------------------------------------------Framework --------------------------------*/

hr {
  margin: 0px;
  padding: 0px;
  border-top: 1px dashed #999;
}
