.blog-left-elements {
  display: flex !important;
  justify-content: center !important;
}

.left-side-cards {
  background-color: #f5f5f5 !important;
  margin-bottom: 40px;
}

.ant-card-head {
  color: black !important;
}

.left-side-top-content ul {
  padding: 0px 50px !important;
}

.left-side-top-content li {
  list-style-type: none;
  padding: 5px 0px;
  border-bottom: 1px solid; 
}

.left-side-bottom-content li {
  list-style-type: none;
}

.left-side-top-content li a {
  display: inline;
  color: inherit;
  text-decoration: none;
  padding-bottom: 9px; 
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-card {
  border-radius: none !important;
}

.right-icon-posts p {
  margin: 0px !important;
  font-size: 12px;
}

.title-right-post {
  font-weight: bold;
  font-size: 20px;
}

.left-side-bottom-content li {
  padding-bottom: 15px;
}
