#unique-word-def {
  background-color: transparent;
  padding: 20px !important;
}

.unique-word-container {
  background-color: rgba(206, 178, 252, 0.48);
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

/* Modify these styles for Vercel compatibility */

/* Card Head */
.ant-card .ant-card-head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 56px;
  margin-bottom: -1px;
  /* padding: 0 24px; */
  font-weight: 600;
  font-size: 16px;
  background: transparent;
  border-bottom: none !important;
  border-radius: 8px 8px 0 0;
}

/* Primary Button */
.ant-btn-primary {
  color: #fff;
  background-color: #2b2676;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

/* Card Body */
.ant-card .ant-card-body {
  padding: 26px;
  border-radius: 0 0 8px 8px;
}

/* Add these styles to your CSS or style sheet */

body::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: #2b2676; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border radius of the scrollbar thumb */
}

body::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
}

.ant-card.ant-card-hoverable {
  cursor: pointer;
  transition: none !important;
}

.ant-card.ant-card-hoverable:hover {
  border-color: none !important;
  box-shadow: none !important;
}


body {
  max-width: 100%;
  overflow-x: hidden;
}
.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #5d3fd3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
}

.three-body__dot:after {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
}

.three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15)
    ease-in-out;
}

.three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}

/*savebutton*/
.cssbuttons-io-button {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 17px;
  padding: 0.8em 1.5em 0.8em 1.2em;
  color: white;
  background: #ad5389;
  background: linear-gradient(
    0deg,
    rgba(77, 54, 208, 1) 0%,
    rgba(132, 116, 254, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #4d36d0be;
  letter-spacing: 0.05em;
  border-radius: 20em;
}

.cssbuttons-io-button svg {
  margin-right: 8px;
}

.cssbuttons-io-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #4d36d0be;
}

.cssbuttons-io-button:active {
  box-shadow: 0 0.3em 1em -0.5em #4d36d0be;
}

.like-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px 20px 22px;
  box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background-color: #e8e8e8;
  border-color: #ffe2e2;
  border-style: solid;
  border-width: 9px;
  border-radius: 35px;
  font-size: 25px;
  font-weight: 900;
  color: rgb(134, 124, 124);
  font-family: monospace;
  transition: transform 400ms cubic-bezier(0.68, -0.55, 0.27, 2.5),
    border-color 400ms ease-in-out, background-color 400ms ease-in-out;
  word-spacing: -2px;
}

@keyframes movingBorders {
  0% {
    border-color: #fce4e4;
  }

  50% {
    border-color: #ffd8d8;
  }

  90% {
    border-color: #fce4e4;
  }
}

.like-button:hover {
  background-color: #eee;
  transform: scale(105%);
  animation: movingBorders 3s infinite;
}

.like-button svg {
  margin-right: 11px;
  fill: rgb(255, 110, 110);
  transition: opacity 100ms ease-in-out;
}

.filled {
  position: absolute;
  opacity: 0;
  top: 20px;
  left: 22px;
}

@keyframes beatingHeart {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.15);
  }

  30% {
    transform: scale(1);
  }

  45% {
    transform: scale(1.15);
  }

  60% {
    transform: scale(1);
  }
}

.like-button:hover .empty {
  opacity: 0;
}

.like-button:hover .filled {
  opacity: 1;
  animation: beatingHeart 1.2s infinite;
}
/* vido styling */
.custom-video-player {
  position: relative;
  transition: filter 0.3s;
  margin-left:14px ;
}

.controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 40px;
  color: #fff;
  transition: color 0.3s;
}

/* .controls.playing .toggleButton:hover {
  color: #978bd7be;
} */

.replay-button {
  margin-left: 10px;
}

.custom-video-player.hovered .controls {
  opacity: 1;
}

.custom-video-player .controls {
  opacity: 0;
  transition: opacity 0.3s;
}
.custom-video-player:hover {
  filter: brightness(70%);
}
/* vido styling */

.speed-control {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.speed-options {
  background-color: #333;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.speed-options button {
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  padding: 5px;
  text-align: left;
}


.speed-options button:hover {
  background-color: #555;
}
.video-word {
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}


/* 
@media screen and (min-width: 1200px) and (max-width: ) {
  .video-word {
    width: 90% !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .custom-video-player{
    margin-left:0px !important;
  }
} */



@media  (min-width: 1500px) {
  .custom-video-player {
    min-height:400px !important
  }
}
@media  screen and (min-width: 1400px) and (max-width: 1500px)  {
  .custom-video-player {
    min-height:400px !important
  }
}
@media  screen and (min-width: 1300px) and (max-width: 1400px)  {
  .custom-video-player {
    min-height:350px !important
  }
}
@media  screen and (min-width: 1200px) and (max-width: 1300px)  {
  .custom-video-player {
    min-height:320px !important
  }
}
@media  screen and (min-width: 1100px) and (max-width: 1200px)  {
  .custom-video-player {
    min-height:300px !important
  }
}
@media  screen and (min-width: 1000px) and (max-width: 1100px)  {
  .custom-video-player {
    min-height:270px !important
  }
}
@media  screen and (min-width: 900px) and (max-width: 1000px)  {
  .custom-video-player {
    min-height:250px !important
  }

}
@media  screen and (min-width: 800px) and (max-width: 900px)  {
  .custom-video-player {
    min-height:230px !important
  }
  .video-word {
    width: 88% !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .custom-video-player{
    margin-left:0px !important;
  }
}
@media  screen and (min-width: 700px) and (max-width: 800px)  {
  .custom-video-player {
    min-height:260px !important
  }
  .video-word {
    width: 88% !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .custom-video-player{
    margin-left:0px !important;
  }

}
@media  screen and (min-width: 600px) and (max-width: 700px)  {
  .custom-video-player {
    min-height:260px !important
  }
}
@media  screen and (min-width: 500px) and (max-width: 600px)  {
  .custom-video-player {
    min-height:250px !important
  }
}
@media screen and (min-width: 450px) and (max-width: 500px)  {
  .custom-video-player {
    min-height:160px !important
  }
}

@media  screen and (min-width: 400px) and (max-width: 450px)  {
  .custom-video-player {
    min-height:160px !important
  }
  .video-word {
    width: 88% !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .custom-video-player{
    margin-left:0px !important;
  }
}


@media  screen and (min-width: 200px) and (max-width: 400px)  {
  .custom-video-player {
    min-height:167px !important
  }
  .video-word {
    width: 88% !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .custom-video-player{
    margin-left:0px !important;
  }
}



@media screen and (min-width: 450px) and (max-width: 770px) {
  .video-word {
    width: 90% !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .custom-video-player{
    margin-left:0px !important;
  }
}
/* 
@media screen and (min-width: 300px) and (max-width: 450px)  {
  .video-word {
    width: 66% !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .custom-video-player{
    margin-left:0px !important;
  }
}
 */
@media  (max-width: 515px)  {
  .hide-download-button{
  display: none;
  }
  .cssbuttons-io-button {
padding: 5px 17px ;
  }
}


/* 

@media  screen and (min-width: 1300px) and (max-width: 1300px)  {
  .custom-video-player {
    min-height:400px !important
  }
} */


/* @media  (max-width: 1200px) {
  .custom-video-player{
    min-height:200px!important
  } 
} */



