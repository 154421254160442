
.profile-arrow-icon {
  margin-left: 100px;
}

.profile-photo-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  color: white;
}

.button-profile-photo {
  /* width: 200px; */
  /* margin-top: 5px;
  padding: 8px 16px;
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 20px;
  font-weight: 400; */
}
/* 
.button-profile-photo:hover {
  background-color: transparent;
} */
.profile-heart-icon,
.profile-exit-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.profile-heart-icon svg,
.profile-exit-icon svg {
  font-size: 24px;
  margin-right: 20px;
  margin-left: 0px;
}

@media (max-width: 768px) {
  .circular {
    width: 100%;
    max-width: none;
  }
}

.profile-accountSettings {
  /* margin-top: 15px; */
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 20px;
}

.falock {
  color: #2b2676;
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  text-align: left;
  margin-top: 25px;
}

.falock p {
  margin: 0; 
}




.change-password-heading {
  text-align: left;
}

.changeEmail,
.changePass,
.newPass {
  margin-bottom: 15px;
}

.profile-label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}

.profile-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.profile-button {
  text-align: right;
}

.profile-button button {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}

.profile-save-button {
  background-color: #2b2676;
  color: #fff;
  border: none;
}

.profile-save-button:hover {
  background-color: #2b2676;
}

.profile-heading {
  color: #2b2676;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
}

.profile-heading {
  font-size: 20px;
  font-weight: bold;
  padding: 15px;
  margin: 0;
  /* margin-left: 35px; */
}

.fullname,
.username,
.email{
  padding: 15px;
  /* margin-left: 35px; */
  font-size: 16px;
  font-weight: 400;
}

.first-frame {
  width: auto;
  height: auto;
  padding: 10px;
}

.profile-box {
  /* margin-top: 200px; */
  overflow: hidden; 
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px #2b2676;
}

.edit-details {
  color: #2b2676;
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  /* margin-left: 35px; */
}

.user_name {
  margin-top: 10px;
  color: #2b2676;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.custom-col1 {
  color: #2b2676;
  font-size: 16px;
  font-weight: 400;
}

/* .lok{
  margin-top: 10px;
} */

.profile-accountSettings {
  margin-top: 20px;
  color: #2b2676;
  font-size: 16px;
  font-weight: 400;
}

/* 
.photo-box {
  margin-top: 200px;
} */

/* .profile-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
} */


.fav-box {
  /* margin-top: 40px; */
  /* margin-left: 180px; */
  /* margin-right: auto; */
  /* width: 100%;  */
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px #2b2676;
  padding: 20px;
  
}

.empty-favorites {
  text-align: center;
}

.profile-heading {
  margin-top: 10px;
}

.words-details,
.sentences-details {
  margin-top: 10px;
}

.favorite-list {
  margin-top: 10px;
}

@media (max-width: 200px) {
  .profile-input edit-input {
width: 60%;  }
}


.error-input {
  border-color: red; /* Add your desired error state styling */
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


.profile-photo-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-profile-photo {
  cursor: pointer;
  width: 50%;
  height: 40px;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-profile-photo:hover {
  background-color: transparent;
}

.button-profile-photo input[type="file"] {
  display: none;
}

.button-profile-photo:last-child {
  margin-bottom: 0;
}

.button-profile-photo,
.profile-save-image {
  width: 100%;
}

.profile-save-image {
  height: 40px;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  margin-top: 10px;
}

.profile-save-image:disabled {
  background-color: #d9d9d9;
  color: #8c8c8c;
  cursor: not-allowed;
}



.formacc{
  width: 100%;
}


.revert-button {
  background-color: #ff4d4f; 
  color: #fff; 
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
}

.revert-button:hover {
  background-color: #d43838; 
}

.fav-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;

text-align: center;  background-color: #fff;
}

.first-frame-empty-favorites {
  text-align: center;
  margin-top: 20px;
}

.fav-edit-details {
  font-size: 20px;
  margin-bottom: 10px;
  color: #2b2676;
}

.fav-profile-heading {
  font-size: 16px;
  color: #555;
}

/* .fav-first-frame-all {
  margin-top: 20px;
} */


.user-card-full {
  overflow: hidden;
}
/* 
.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69,90,100,0.08);
  box-shadow: 0 1px 20px 0 rgba(69,90,100,0.08);
  border: none;
  margin-bottom: 30px;
} */
/* 
.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
} */



h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

@media only screen and (min-width: 1400px){
.profile-p {
  font-size: 14px;
}
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 500px) and (max-width: 700px) {
  
  .profile-image-row {
   justify-content: center !important;
  }
}
.tab-border{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}