.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.swiper-3d {
  perspective: 145px !important;
  
}
.ant-spin-container{
border-radius:10px;

}


@import url('https://fonts.googleapis.com/css?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css?family=Hind&display=swap');



:root {
  --border-radius: 20px;
}
/* 
body, html {
  background: #e2e9f4;
  display: grid;
  height: 100%;
  grid-template: 1fr/100%;
  place-items: center;
} */

.Card {
  padding: 1px;
  border-radius: var(--border-radius);
  /* background: linear-gradient(-67deg, rgba(200, 216, 231, 0.7), rgba(255,255,255,0.8)); */
  overflow: hidden;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  width: 380px;
}

.CardInner {
  /* padding: 16px 16px; */
  /* background-color: #e2e9f4; */
  border-radius: var(--border-radius);
}

.search-component-container {
  display: flex;
  position: relative;
}

.Icon {
  min-width: 46px;
  /* min-height: 46px; */
  display: flex;
  right:0;
  top:25%;
  z-index: 10000;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  /* border:1px solid  */
  /* margin-right: 12px; */
  /* box-shadow: 
    -2px -2px 6px rgba(255, 255, 255, 0.6),
    2px 2px 12px #c8d8e7;
     */
  svg {
    transform: translate(-1px, -1px);    
  }
}

/* label {
  font-family: "Hind", sans-serif;
  display: block;
  color: #3c4b66;
  margin-bottom: 12px;
  background: linear-gradient(45deg, rgba(107, 123, 143, 1), #3c4b66);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.InputContainer {
  width: 100%;
}

.search-component-input {
  /* background-color: #e3edf7; */
  padding: 10px 20px;
  border: none;
  display: block;
  /* font-family: 'Orbitron', sans-serif; */
  font-weight: 600;
  color: #a9b8c9;
  -webkit-appearance: none;
  transition: all 240ms ease-out;
  width: 100%;
}

input::placeholder {
  color: #6d7f8f;
}

input:focus {
  outline: none;
  color: #6d7f8f;
  background-color: #e3edf7;
}

.InputContainer {
  /* --top-shadow: inset 1px 1px 1px #c5d4e3, inset 1px 1px 4px #c5d4e3; */
  /* --bottom-shadow: inset -2px -2px 4px rgba(255,255,255,0.7); */
  border:1px solid  #a9b8c9;
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.InputContainer:before,
.InputContainer:after {
  left: 0;
  top: 0;
  display: block;
  content: "";
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.InputContainer:before {
  box-shadow: var(--bottom-shadow);
}

.InputContainer:after {
  box-shadow: var(--top-shadow);
}

.search-result-link{
  box-shadow: 
  -2px -2px 6px rgba(255, 255, 255, 0.6),
  2px 2px 12px #c8d8e7;
background-color: white;
}

::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #7c77c1; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the scrollbar track */
}