.subtitle-post-card {
  font-size: 12px;
  height: 20px;
  width: 100px;
}
/* .button-read-more {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: var(--clr);
  color: #fff;
  border-radius: 10rem;
  font-weight: 600;
  padding: 0.4rem 0.7rem;
  padding-left: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color .3s all;

}

.button__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: var(--clr);
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.button-read-more:hover {
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}

.button-read-more:hover .exlore-all {
 color: white;

}

.button__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.button-read-more:hover .button__icon-svg:first-child {
  transition: transform .3s ease-in-out;
  transform: translate(150%, -150%);
}

.button-read-more:hover .button__icon-svg--copy {
  transition: transform .3s ease-in-out .1s;
  transform: translate(0);
}
 */
 .button-read-more {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.button-read-more  {
  --primary-color: #111;
  --hovered-color: #c84747;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
}

.button-read-more p {
  margin: 0;
  position: relative;
  font-size: 20px;
  color: var(--primary-color);
}

.button-read-more::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}


/* .button-read-more:hover::after {
  width: 100%;
}

.button-read-more:hover p::before {
  width: 100%;
}
 */
.button-read-more:hover svg {
  transform: translateX(6px);
  color: #2b2676;
}

.button-read-more svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}
.card-title{
  color: #111;
}
.card-text{
  color:#4F4F4F;
}
/* 
.card-blog:hover svg{
  transform: translateX(4px);
  color:#2b2676;

} */
.card-blog:hover p::before {
  width: 100%;

}
.card-blog:hover{
  cursor: pointer;
}

.card-blog:hover::after {
  width: 100%;


}

.card-blog{
  height: 100%;
}




.card-body {
  flex: 1 1 auto;
  /* padding: var(--mdb-card-spacer-y) var(--mdb-card-spacer-x); */
  color: var(--mdb-card-color);
  padding: 25px 12px;
}


.img-fluid.image-blogs {
  max-width: 100%;
  height: 261px;
}

.unique-blog-title{
  font-size:30px;
 color:#2b2676;
 font-weight: 700;

}
.unique-blog-desc{
  font-size:19px;
}

.unique-image-blogs{
  /* width: 80%; */
height: 100%;
}
.blog-image-div{
  display: flex;
  justify-content: center;
}
.unique-blog-row-width{

  width: 85%;}

@media (max-width:700px) {
  .unique-blog-row-width{

    width: 100%;
  }
  
}