.login-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.login-frame {
  width: 100%;
  max-width: 550px;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #FFF;

}

.loginForm{
   width: 100%;
  /*display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  padding:15px
}

element.style{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}


.login-heading {
  text-align: center;
  color: #2b2676;
  font-size: 36px;
  font-weight: 400px;
  word-wrap: break-word;
}

.login-text-input {
  padding: 8px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 1px solid #2b2676;
}

.signIn-button button,
.login-button button {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.login-button {
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.signIn-button button:hover,
.login-button button:hover {
  background-color: #2980b9;
}

.login-checkbox-row {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.login-rememberCheckbox {
  color: #2b2676;
  /* word-wrap: break-word; */
}

/* .login-hr {
  border: 1px solid #ccc;
  width: 100%;
  margin: 10px 0;
} */

.forgot-password {
  cursor: pointer;
  color: #2b2676;
  white-space: nowrap;
}

.login-error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.login-term {
  display: inline;
  color: #2b2676;
}


.mb-4{
  color:#2b2676;
}
@media only screen and (max-width: 600px){
  .login-frame{
    height: 600px;
  }
}

@media only screen and (max-width: 460px){
  .login-frame{
    height: 650px;
  } 
  .login-checkbox-row{
    display: flex;
    flex-direction: column;
  }
  .forgot-password{
    display: flex;
    flex-direction: column;
  }
}




























