.left-top-title {
  color: #2b2676;
}
.left-side-top-content p {
  padding: 10px 0px;
}
.left-side-top {
  padding: 20px 40px;
}

.left-top-icons {
  margin: 20px 0px;
  color: #2b2676;
}

.contact-page-form-title {
  padding: 30px 0px;
  color: #2b2676;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #2b2676;
}

.contact-page-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px; 
  margin: auto;
  display: flex;
  align-items: center;
}

.group {
  position: relative;
}

.contact-page-form .group label {
  font-size: 14px;
  color: #2b2676;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.contact-page-form .group input,
.contact-page-form .group textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  outline: 0;
  width: 450px; 
  background-color: transparent;
}

.contact-page-form .group input:placeholder-shown + label,
.contact-page-form .group textarea:placeholder-shown + label {
  top: 10px;
  background-color: transparent;
}

.contact-page-form .group input:focus,
.contact-page-form .group textarea:focus {
  border-color: #2b2676;
}

.contact-page-form .group input:focus + label,
.contact-page-form .group textarea:focus + label {
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: #2b2676;
  font-weight: 600;
  font-size: 14px;
}

.contact-page-form .group textarea {
  resize: none;
  height: 100px;
}

.contact-page-form button {
  background-color: #2b2676;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 40px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%; 
  margin-top: 5px;
  align-self: center;
}

.contact-page-form button:hover {
  background-color: #27408b;
}

.success-message {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .contact-page-form .group input,
  .contact-page-form .group textarea {
    width: 100%;
  }

  button {
    font-size: 16px; 
  }
}