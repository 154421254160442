.signUp-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.sign-heading {
  text-align: center;
  color: #2b2676;
  font-size: 36px;
  font-weight: 400px;
  word-wrap: break-word;
}
.signUp-frame {
  width: 100%;
  max-width: 50px;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #FFF;
}

.signUp-form-group {
  margin-bottom: 20px;
}

.signUp-text-input {
  margin-bottom: 15px;
  padding: 10px 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  border: 1px solid #2b2676;
}

.signUp-label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}

.signUp-term {
  display: inline;
  color: #2b2676;
}
.check {
  margin-top: 18px;
  margin-left: 16px;

}
.box {
  color: #2b2676;
}

@media only screen and (max-width: 600px) {
  .signUp-form-container {
    max-width: 90%;
  }
  .sign-head-text{
    display: flex;
    flex-direction: column;
  }
  .sign-heading{
    font-size: 30px;
  }
}

.login-option {
  text-align: center;
}

.sign-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  padding: 30px;
}

.signUp-error-message {
  color: red;
  font-family: "Inter";
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.signUp-user {
  text-decoration: none;
  color: #2b2676;
  display: block;
  text-align: center;
  margin-top: 20px;
}

.MuiCheckbox-root {
  padding: 0;
}

.MuiIconButton-label {
  position: relative;
}

.textfield{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}












/* 
.register_container {
  color:lightblue;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
}
.register_screen {
  background: linear-gradient(90deg, #191646, #9994DC);
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 0px 24px #2b2676;
}
.register_screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}
.register_screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}
.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape5{
  height: 520px;
  width: 520px;
  background: #403b83;
  top: 130px;
  right: 120px;
  border-radius: 0 72px 0 0;

}
.screen__background__shape1 {
  height: 520px;
  width: 520px;
  background: #FFF;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}
.screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: #665FCB;
  top: -172px;
  right: 0;
  border-radius: 32px;
}
.screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #2b2676, #665FCB);
  top: -24px;
  right: 0;
  border-radius: 32px;
}
.screen__background__shape4 {
  height: 400px;
  width: 200px;
  background: #39347C;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}
.register {
  padding: 30px;
  padding-top: 156px;
}
.register__field {

  padding: 20px 0px;
  position: relative;
}
.login__icon {
  position: absolute;
  top: 30px;
  color: #7875B5;
}
.login__input {
  border: none;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 75%;
  transition: .2s;
}
.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #6A679E;
}
.register__submit {

  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #D4D3E8;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: .2s;
}
.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #6A679E;
  outline: none;
}
.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875B5;
  
}
.social-login {
  position: absolute;
  height: 140px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fff;
}
.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-login__icon {
  padding: 20px 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0px 0px 8px #7875B5;
}
.social-login__icon:hover {
  transform: scale(1.5);
}



.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-frame {
  width: 100%;
  max-width: 550px;
  padding: 40px;
  height: 700px;
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.login-heading {
  color: #2b2676;
  font-size: 32px;
  font-weight: 400px;
  word-wrap: break-word;
  margin-top: 0px;
}

.login-text-input {
  padding: 8px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 1px solid #2b2676;
}

.signIn-button button,
.login-button button {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.register__submit{
  width: 35%;

}
.login-button {

  display: flex;
  justify-content: center;
  align-items: center;
}

.signUp-user{
  color:white;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signIn-button button:hover,
.login-button button:hover {
  background-color: #2980b9;
}

.login-checkbox-row {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.login-rememberCheckbox {
  word-wrap: break-word;
}

hr {
  
  border: 1px solid #ccc;
  width: 100%;
  margin: 10px 0;
}

.forgot-password {
  cursor: pointer;
  color: white;
  white-space: nowrap;
}

.new-user{
  cursor: pointer;
  color: white;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.login-term {
  display: inline;
  color: white;
}


.mb-2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.signUp-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.sign-heading {
  text-align: center;
  color: #2b2676;
  font-size: 36px;
  font-weight: 400px;
  word-wrap: break-word;
}
.signUp-frame {
  margin-top: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 500px;
  background-color: #f9f9f9;
}

.signUp-form-group {
  margin-bottom: 20px;
}

.signUp-text-input {
  margin-bottom: 15px;
  padding: 10px 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  border: 1px solid #2b2676;
}

.signUp-label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}

.signUp-term {
  display: inline;
  color: white;
}
.check {
  margin-top: 18px;
  margin-left: 5px;
}
.box {
  color: white;
}

@media only screen and (max-width: 600px) {
  .signUp-form-container {
    max-width: 90%;
  }
  .sign-head-text{
    display: flex;
    flex-direction: column;
  }
  .sign-heading{
    font-size: 30px;
  }
}

.login-option {
  text-align: center;
}

.sign-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  padding: 30px;
}

.signUp-error-message {
  color: red;
  font-family: "Inter";
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.signUp-user {
  text-decoration: none;
  color: #2b2676;
  display: block;
  text-align: center;
  margin-top: 20px;
}

.MuiCheckbox-root {
  padding: 0;
}

.MuiIconButton-label {
  position: relative;
}
@media only screen and (max-width: 600px){
  .register_screen{
    height: 100%;
    width: 400px;

  }
 
 .register__field{
  width: 80%;

 }

 .screen__background__shape1 {
  width: 520px;
  top: -160px;
  right: 30px; }
}

@media only screen and (max-width: 460px){
  .login-frame{
    height: 650px;
    width: 400px;
  } 
  .salam{
    width: 80%;
  }

  .login-checkbox-row{
    display: flex;
    flex-direction: column;
  }
  .forgot-password{
    display: flex;
    flex-direction: column;
  }
}



 */