.viewButton {
  margin-top: 20px;
  padding: 17px 10px;
  border-radius: 50px;
  cursor: pointer;
  border: 0;
  background-color: white;
  box-shadow: rgb(0 0 0 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
  max-width: 300px;
}

.viewButton:hover {
  letter-spacing: 3px;
  background-color: hsl(261deg 80% 48%);
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(93 24 220) 0px 7px 29px 0px;
}

.viewButton:active {
  letter-spacing: 3px;
  background-color: hsl(261deg 80% 48%);
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(93 24 220) 0px 0px 0px 0px;
  transform: translateY(10px);
  transition: 100ms;
}

.page-top-name {
  font-size: 30px !important;
  /* color  : rgb(47, 114, 229); */
  color: #2b2676;

  font-weight: 600;
  padding-bottom: 10px;
}
.page-top-name p {
  margin: 0px;
}
