/* .custom-card-text{
    color:white;
    font-size:20px;
    margin: 0px;
    text-align: center ;

}
.cusom-card-title{
    color:white;
    font-size:20px;
    font-weight: 600;;

}

.cusom-card-subtitle{
    color:white;
    font-size:20px;

}

.custom-card{
    background-color: 
    #bfbce8;
} */

/* .ant-tabs-nav-wrap {
  justify-content: center !important;
}

.ant-tabs-tab {
  margin-left: 20px !important;
  color: #2b2676;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs .ant-tabs-tab:hover {
  color: #2b2676;
  text-shadow: 0 0 0.25px currentcolor;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #2b2676;
  font-weight: 700;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-top
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-bottom
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-top
  > div
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-bottom
  > div
  > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #2b2676;
  content: "";
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs .ant-tabs-ink-bar {
  background: #2b2676;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-ink-bar,
:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-bottom
  > .ant-tabs-nav
  .ant-tabs-ink-bar,
:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-ink-bar,
:where(.css-dev-only-do-not-override-2q8sxy).ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-ink-bar {
  height: 3px;
}  */


/* Center align the tabs */


/* Adjust the margin of each tab */
/* .ant-tabs-tab {
  margin-left: 8px !important;
  color: #2b2676;

} */

@media  (min-width: 552px) {
  .ant-tabs-nav-wrap {
    justify-content: center !important;
  }
  
}

/* Add text shadow on hover */
.ant-tabs .ant-tabs-tab:hover {
  color: #2b2676;
  /* color:  rgb(47, 114, 229); */

 
  text-shadow: 0 0 0.25px currentcolor;
}

/* Style the active tab */
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #2b2676;
  content: "";
}

/* Style the ink bar */
.ant-tabs .ant-tabs-ink-bar {
  /* background:rgb(47, 114, 229); */
  
  background: #2b2676;
  height: 3px;
}

/* Style the active tab button */
.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2b2676;
  /* color:rgb(47, 114, 229); */
  font-weight: 700;
}

/* //budan */
/* #countries-tab .custom-card{
    border:1px solid  black;
}

.countries-custom-card{
    border:2px solid black !important;
    background-color: white !important ;
    color: #2b2676 !important;
    border-radius: 7px !important;
    text-align: center;
}
/* //budan */

/* Tab styles */
.ant-tabs .ant-tabs-tab {
  /* color:rgb(47, 114, 229); */

  color: #2b2676;
  margin-left: 5px;
}



.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 60px;
  /* color:rgb(47, 114, 229); */
  color: #2b2676;

  /* color: #2b2676; */
}

/* Card body styles */
.ant-card-body {
  padding: 6px;
  border-radius: 0 0 8px 8px;
}

/* Big card body styles */
.big-card-body .ant-card-body {
  padding: 10px;
}

/* 
.custom-card{
    padding: 8px !important;
    text-align: center;
} */
.card-col {
  display: flex !important;
  justify-content: center !important;
}

.body-margin {
  margin: 0px 50px !important;
}

/* Tab button styles */
.ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-size: 18px;
}

/* width: 200px; */
.custom-card {
  position: relative;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(
    109.6deg,
    rgb(121, 121, 216) 11.2%,
    rgb(172, 165, 228) 100.2%
  );
  /* background-color: rgb(47, 114, 229); */
  font-size: 25px;
  font-weight: bold;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  transition: 0.4s ease-out;
}

.custom-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.page-top-name {
  font-size: 30px !important;
  color: #2b2676;
  font-weight: 600;
}
.page-top-name p {
  margin: 0px;

}

.page-top-title{
  padding: 15px;
}


.countries-custom-card {
  background: linear-gradient(
    109.6deg,
    rgb(160, 160, 229) 11.2%,
    rgb(188, 182, 236) 100.2%
  );
  color: #2b2676 !important;
  border-radius: 15px !important;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  height: 60px;
  width:300px;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.countries-custom-card p {
  margin: 0px !important;
}
.countries-custom-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
/* 1st tabulation responsivenes */
/* Media queries for responsiveness */
@media screen and (min-width: 1264px) and (max-width: 1365px) {
  #rc-tabs-1-panel-1 .custom-card {
    width: 187px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1264px) {
  #rc-tabs-1-panel-1 .custom-card {
    width: 160px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
  #rc-tabs-1-panel-1 .custom-card {
    width: 145px !important;
  }
}

/* @media screen and (min-width: 770px) and (max-width: 992px) {
  #rc-tabs-1-panel-1 .custom-card {
    width: 200px !important;
  }
} */

@media screen and (min-width: 676px) and (max-width: 770px) {
  #rc-tabs-1-panel-1 .custom-card {
    width: 184px !important;
  }
}

/* @media screen and (min-width: 500px) and (max-width: 676px) {
  #rc-tabs-1-panel-1 .custom-card {
    width: 135px !important;
  }
} */

/* @media screen and (min-width: 300px) and (max-width: 500px) {
  #rc-tabs-1-panel-1 .custom-card {
    width: 120px !important;
  }
} */

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet,
  #rc-tabs-1-panel-4 .custom-card {
    padding: 8px !important;
    width: 170px !important;
  }
}
@media screen and (min-width: 991px) and (max-width: 1100px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet,
  #rc-tabs-1-panel-3 .custom-card #rc-tabs-1-panel-4 .custom-card {
    padding: 8px !important;
    width: 107px !important;
  }
}

@media screen and (min-width: 760px) and (max-width: 990px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet,
  #rc-tabs-1-panel-3 .custom-card #rc-tabs-1-panel-4 .custom-card {
    width: 170px !important;
  }
}
@media screen and (min-width: 570px) and (max-width: 690px) {
  #rc-tabs-1-panel-3 .custom-card #rc-tabs-1-panel-4 .custom-card {
    width: 160px !important;
  }
}

/* @media screen and (min-width: 300px) and (max-width: 570px) {
  #rc-tabs-1-panel-3 .custom-card #rc-tabs-1-panel-4 .custom-card {
    width: 140px !important;
  }
} */
/* 2nd tabulation resonsivenes */

@media screen and (min-width: 1300px) and (max-width: 1902px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 150px !important;
  }
}

@media screen and (min-width: 1204px) and (max-width: 1321px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 135px !important;
  }
}
@media screen and (min-width: 1061px) and (max-width: 1204px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 118px !important;
  }
}
@media screen and (min-width: 991px) and (max-width: 993px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 105px !important;
  }
}

@media screen and (min-width: 760px) and (max-width: 991px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 272px !important;
  }
}

@media screen and (min-width: 842px) and (max-width: 942px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 233px !important;
  }
}

@media screen and (min-width: 680px) and (max-width: 842px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 183px !important;

  }
  

}

@media screen and (min-width: 604px) and (max-width: 682px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 159px !important;
  }
}

@media screen and (min-width: 575px) and (max-width: 604px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 146px !important;
  }
}

/* @media screen and (min-width: 500px) and (max-width: 575px) {
  #rc-tabs-1-panel-2 .custom-card-alphabet {
    width: 167px !important;
  }
} */

#rc-tabs-1-panel-3 .custom-card,
#rc-tabs-1-panel-4 .custom-card {
  background-color: white !important;
  border: 2px solid #65619b !important;
  border-radius: 15px !important;
}
#rc-tabs-1-panel-3 .custom-card-text,
#rc-tabs-1-panel-4 .custom-card-text {
  color: #5e5aa2 !important;
}
/* 3rd one, 4th one responsivenes */

@media screen and (min-width: 1213px) and (max-width: 1321px) {
  .countries-custom-card,
  #rc-tabs-1-panel-4 .custom-card {
    width: 226px !important;
  }
}

@media screen and (min-width: 990px) and (max-width: 1213px) {
  .countries-custom-card,
  #rc-tabs-1-panel-4 .custom-card {
    width: 210px !important;
  }
}

@media screen and (min-width: 780px) and (max-width: 990px) {
  .countries-custom-card,
  #rc-tabs-1-panel-4 .custom-card {
    width: 220px !important;
  }
}

@media screen and (min-width: 770px) and (max-width: 790px) {
   .countries-custom-card,
  #rc-tabs-1-panel-4 .custom-card {
    width: 200px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 770px) {
  .countries-custom-card,
  #rc-tabs-1-panel-4 .custom-card {
    width: 190px !important;
  }
}

@media screen and (min-width: 570px) and (max-width: 720px) {
  .countries-custom-card,
  #rc-tabs-1-panel-4 .custom-card {
    width: 220px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 570px) {
   .countries-custom-card,
  #rc-tabs-1-panel-4 .custom-card {
    width: 300px !important;
  }
  .seach-title-dictionary{
    display: flex;
    justify-content: center;
  }

}

@media screen and (min-width: 300px) and (max-width: 400px) {
  .countries-custom-card,
 #rc-tabs-1-panel-4 .custom-card {
   width: 250px !important;
 }
 .seach-title-dictionary{
   display: flex;
   justify-content: center;
 }

}

/* @media screen and (min-width: 400px) and (max-width: 500px) {
  .countries-custom-card,
 #rc-tabs-1-panel-4 .custom-card {
   width: 152px !important;
 }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
  .countries-custom-card,
 #rc-tabs-1-panel-4 .custom-card {
   width: 142px !important;
 }
} */

.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #5d3fd3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
}

.three-body__dot:after {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
}

.three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15)
    ease-in-out;
}

.three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}

/* //circle la olaon */

.wallet {
  --bg-color: linear-gradient(
    109.6deg,
    rgb(121, 121, 216) 11.2%,
    rgb(172, 165, 228) 100.2%
  );
  --bg-color-light: #f0e7ff;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.custom-card-alphabet {
  height: 150px;
  background: #efeaf7;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

.custom-card-alphabet:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);

}
.custom-card-alphabet:hover .custom-card-alphabet-content {
  color: black !important;
}
.custom-card-alphabet:hover .day-text {
  color: white !important;
}

/* .custom-card-alphabet:hover .overlay {
  transform: scale(4) translateZ(0);
 background: url('/public/jestdilistatic.jpg') center/cover; 

} */

.custom-card-alphabet:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
  background: transparent;

}
/* .custom-card-alphabet:hover .circle-container {
display: none;
} */






/* //burdan 
.custom-card-alphabet:hover .circle:after {
  content: "";
  width: 30%;
  height: 30%;
  display: block;
  position: absolute;

  border-radius: 50%;
  transition: transform 0.4s ease-out;
  transform: rotateY(180deg);
}
 */

.custom-card-alphabet:hover .circle:hover:after {
  opacity: 1; /* Set the opacity to 1 to show the content on circle hover */
}

.card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.custom-card-alphabet .custom-card-alphabet-content {
  font-size: 17px;
  color: white;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
  
}


.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #cebbed;
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: relative;
  z-index: 1;
  transition: all 0.4s ease-out;
}

.circle:after {
  content: "";
  width: 80px;
  height: 80px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  transition: all 0.4s ease-out;
}

.circle svg {
  z-index: 10000;
  transform: translateZ(0);
}

.overlay {
  width: 30px;
  position: absolute;
  height: 30px;
  border-radius: 50%;
  background: var(--bg-color);
  /* top: 55px;
  left: 55px; */
  z-index: 0;
  transition: transform 0.4s ease-out;
}

/* circle olan */

.ant-pagination-options {
  display: none !important;
}