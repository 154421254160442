body{
  background-color: 	FFFEF7 !important    ;
  min-height: 400px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px 25px !important;
  }
}
@media screen and (min-width: 1450px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px 25px !important;
    font-size: 17px;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1450px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px 20px !important;
    font-size: 17px;
  }
}

@media screen and (min-width: 980px) and (max-width: 1250px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px 14px !important;
    font-size: 17px;
  }
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding: 0px 65px !important;
}

.navbar-login-signup {
  white-space: nowrap;
  padding: 4px 13px;
  border: 1px solid #2b2676;
  /* border: 1px solid rgb(47, 114, 229); */

  /* color:"rgb(47, 114, 229) " */
  border-radius: 10px;
  font-size: 16px;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: 17px !important;
  border-radius: 10px !important;
}
.navbar-signup {
  background-color: #2b2676;
  /* background-color:  rgb(47, 114, 229); */

 
  color: white;
}

.ant-input{
  margin: 0px !important;
}
/* 
.ant-input-wrapper {
  padding: 5px 20px;
} */
.ant-list-empty-text{
  display: none !important;
}
.search-box{
  /* padding: 0px 20px;; */
  max-width: 400px;
}

.ant-spin-container {
  /* height: 0px !important ; */
  overflow-y: auto;
  background-color: #ddd;
  z-index: 1;
}
/* .ant-list{
  width: 90%;;
} */
/* 
.ant-input {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 8px;
} */

/* Style for the search results container */
.search-results {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
}

/* Style for each item in the search results list */
.search-result-link {
  display: block;
  padding: 12px;
  width: 100%;;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
}

.search-result-link:hover {
  background-color: #f0f0f0;
}

/* Style for loading state */
.ant-spin-container {
  /* height: 250px;  */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #ddd;
}


