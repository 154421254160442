.padding-for-sections {
  padding-top: 100px;
}

.komanda-card {
  height: 500px;
  /* margin: 130px; */
  border-radius: 5px;
  /* border: 2px solid #2b2676; */
  background-color: #dddbf3;
  /*     
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */

  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */

  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */

  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.slick-slide > div {
  margin: 0 70px;
}
/*   
  @media (max-width: 1340px) {
    .slick-slide > div {
        margin: 0 15px; 
    }
   
    .meet-team-description{
      font-size:15px;
    }
  }

@media (max-width: 1300px) {
  .slick-slide > div {
      margin: 0 49px; 
  }
 

}

@media (max-width: 1200px) {
  .slick-slide > div {
      margin: 0 15px; 
  }
  .meet-team-description{
    font-size:15px;
  }

}
@media (max-width: 1024px) {
  .slick-slide > div {
      margin: 0 38px; 
  }
 
}

@media (max-width: 765px) {
  .slick-slide > div {
      margin: 0 33px;
  }
 
}


@media (max-width: 600px) {
  .slick-slide > div {
      margin: 0 64px;
  }
 
}

@media (max-width: 450px) {
  .slick-slide > div {
      margin: 0 10px;
  }
} */

@media (min-width: 1900px) {
  .slick-slide > div {
      margin: 0 148px;
  }
} 
@media screen and (min-width: 1800px)and (max-width: 1900px) {
  .slick-slide > div {
      margin: 0 140px;
  }
} 
@media screen and (min-width: 1700px)and (max-width: 1800px) {
  .slick-slide > div {
      margin: 0 124px;
  }
} 
@media screen and (min-width: 1500px)and (max-width: 1700px) {
  .slick-slide > div {
      margin: 0 101px;
  }
} 
@media screen and (min-width: 1400px)and (max-width: 1500px) {
  .slick-slide > div {
      margin: 0 90px;
  }
} 
@media screen and (min-width: 1300px)and (max-width: 1400px) {
  .slick-slide > div {
      margin: 0 68px;
  }
} 
@media screen and (min-width: 1200px)and (max-width: 1300px) {
  .slick-slide > div {
      margin: 0 53px;
  }
} 
@media screen and (min-width: 1100px)and (max-width: 1200px) {
  .slick-slide > div {
      margin: 0 38px;
  }
} 
@media screen and (min-width: 1025px)and (max-width: 1100px) {
  .slick-slide > div {
      margin: 0 22px;
  }
} 
@media screen and (min-width: 1000px)and (max-width: 1025px) {
  .slick-slide > div {
      margin: 0 28px;
  }
} 
@media screen and (min-width: 800px)and (max-width: 900px) {
  .slick-slide > div {
      margin: 0 70px;
  }
} 
@media screen and (min-width: 700px)and (max-width: 800px) {
  .slick-slide > div {
      margin: 0 22px;
  }
} 
@media screen and (min-width: 678px)and (max-width: 700px) {
  .slick-slide > div {
      margin: 0 22px;
  }
} 
@media screen and (min-width: 600px)and (max-width: 678px) {
  .slick-slide > div {
      margin: 0 100px;
  }
} 
@media screen and (min-width: 500px)and (max-width: 600px) {
  .slick-slide > div {
      margin: 0 65px;
  }
} 
@media screen and (min-width: 450px)and (max-width: 500px) {
  .slick-slide > div {
      margin: 0 33px;
  }
} 
@media screen and (min-width: 400px)and (max-width: 450px) {
  .slick-slide > div {
      margin: 0 26px;
  }
} 
@media screen and (min-width: 300px)and (max-width: 400px) {
  .slick-slide > div {
      margin: 0 7px;
  }
} 
.meet-pic-class img {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.meet-team-name {
  /* text-align: center; */
  font-size: 20px;
  color: #2b2676;
  font-weight: bold;
}

.hr {
  margin-left: 20px;
  /* margin-right: auto; */
  border-bottom: 3px solid #2b2676;
}
.meet-team-position {
  padding-top: 21px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 12px;
  color: rgb(47, 114, 229);
  font-weight: bold;
  /* text-align: center; */
}

.meet-team-description {
  margin-left: 20px;
  margin-top: 6px;

  /* text-align: center; */
  margin-right: 20px;
  color: #2b2676;
}

.slick-prev:before,
.slick-next:before {
  opacity: 0.75;
  color: #2b2676;
}

.slick-track {
  margin-bottom: 60px !important;
}
