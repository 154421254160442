.faq-container-for-all{
  height: 400px;
}

/* .faq-col{
  height: 200px;
} */
.faq-accordion-containe {
  padding: 50px;
  background-color: red;
}
/*   
.ant-collapse {
  border: none;
} */

/* .ant-collapse-item {
  border: none;
} */

/* .ant-collapse-header {
  border-bottom: none;
} */

.head-faq {
  color: #2b2676;
  text-align: center;
}

.title-faq {
  font-size: 2em;
}

.container-faq {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.faq-accordion-container {
  margin-top: 30px;
}


.faq-text{
  color: #2b2676;
}

@media only screen and (max-width: 600px) {
  .ant-collapse-header {
      height: 90px;
  }
    
}

/* Container styles */
.faq-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Headline styles */
.title-faq {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Panel styles */
.ant-collapse {
  background-color: #f8f8f8;
  border: none !important;
}

.ant-collapse-item {
  border: 1px solid #ddd;
  /* margin-bottom: 16px; */
}

.ant-collapse-header {
  background-color: #fff;
  border:1px solid #2b2676;
  border-radius: 10px;
  /* border-radius: 10px; */
  padding: 12px 24px;
  font-size: 18px;
  color: #333;

}


.ant-collapse-content {
  border-top: 0;
}

/* Text inside panels */
.faq-text {
  margin: 16px 24px;
  font-size: 16px;
  color: #666;
}